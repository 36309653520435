import profile from './images/profile.png'
import { TypeAnimation } from 'react-type-animation';


function App() {
  return (
    <div className="App m-5">
      <div className="flex space-x-2 items-center">
        <img className="rounded-full" width="100" height="100" src={profile} alt="" />
        <p className="text-4xl md:text-6xl font-sourceCode">Billi Aspinwall</p>
      </div>
      <div>
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed once, initially
            'I specialize in UX Design',
            1000,
            'I specialize in Graphic Design',
            1000,
            'I specialize in Eating Potatoes',
            1000,
            'I specialize in Pilatis',
            1000,
          ]}
          preRenderFirstString={true}
          speed={50}
          style={{ fontSize: '2em' }}
          repeat={Infinity}
        />
      </div>
      <div className="fixed bottom-0 right-0">
        <img width="100" alt="Lime" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Lime.svg/256px-Lime.svg.png?20171220164138" />
      </div>
    </div>
  );
}

export default App;
